import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import Button from '../../../../01_atoms/Button';
import DefaultPicture, { propTypes as picturePropTypes } from '../../../../01_atoms/DefaultPicture';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

// TODO: use CSS modules.
const BBCTAPrimary = ({
  imagePicture,
  button = null,
  title = '',
  list = [],
  color = 'pink',
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb cta primary color-${color} ${generateClassNameByBehaviorSettings(
      behaviorSettings,
    )}`}
    id={uuid}
  >
    {imagePicture && <DefaultPicture loading="lazy" {...imagePicture} />}

    <div className="container">
      <div className="overlay">
        {title && <h2>{title}</h2>}

        {list.length > 0 && (
          <ul className="icon-list">
            {list.map((item) => (
              <li key={item}>
                <p>{item}</p>
              </li>
            ))}
          </ul>
        )}

        {button && button.label && button.nextLink && (
          <Link {...button.nextLink}>
            <Button
              className="d-block bb-cta-primary--button"
              tag="a"
              href={button.nextLink.url}
              type={button.type}
              withArrow={color === 'pink'}
            >
              {button.label}
            </Button>
          </Link>
        )}
      </div>
    </div>
  </div>
);

BBCTAPrimary.propTypes = {
  imagePicture: PropTypes.shape(picturePropTypes).isRequired,
  title: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
    type: PropTypes.oneOf(['primary', 'secondary']),
  }),
  list: PropTypes.arrayOf(PropTypes.string),
  /**
   * Green option displays 'Tick' icon for all points.
   * Pink option displays 'Arrow right' icon for all points.
   */
  color: PropTypes.oneOf(['pink', 'green']),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBCTAPrimary;
